html {
  background-color: #ffa593;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
